// Here you can add other styles
.sidebar .nav-dropdown-toggle::before {
  transform: rotate(-180deg);
}

.sidebar .nav-dropdown-toggle {
  padding: 0.75rem 1rem !important;
}

.sidebar .nav-dropdown.open .nav-link {
  padding-left: 50px;
}

.sidebar-minimized .sidebar .nav-link {
  padding-left: 0 !important;
}

.sidebar .nav-link:hover {
  background: $primary;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: $primary;
}

.titleApp {
  font-family: Nightclub !important;
  font-size: xx-large;
  color: $primary;
}

.ng-invalid.ng-touched:not(form):not(.no-validate) {
  border: 1px solid $danger !important;
  border-radius: 0.25rem !important;
}

.ng-invalid.ng-untouched:not(form):not(.no-validate) {
  border-radius: 0.25rem !important;
}

.ng-select.ng-select-disabled>.ng-select-container {
  .ng-arrow-wrapper {
    display: none;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: $secondary !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: $primary !important;
}

.ng-select .ng-select-container {
  color: $secondary !important;
  border-radius: 0.4rem !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: $gray-700 !important;
}

.modal-backdrop {
  transition-duration: 0.4s;
  background: rgba(55, 58, 71, 0.9);
  transition: opacity 0.3s;
}

.bs-datepicker-head {
  background-color: $primary !important;
}

.bs-datepicker-body table td.week span {
  color: $secondary !important;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]::after,
.theme-green .bs-datepicker-body table td[class*="select-"] span::after {
  background-color: $secondary;
}

.theme-blue .bs-datepicker-body table td span.selected,
.theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*="select-"]::after,
.theme-blue .bs-datepicker-body table td[class*="select-"] span::after {
  background-color: $secondary;
}

.theme-green .bs-datepicker-predefined-btns button.selected {
  background-color: $secondary;
}

.card {
  border: none;
  border-radius: 0.25rem !important;
  padding: 1rem !important;
  margin-bottom: 3rem !important;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

  .card-header {
    background-color: transparent;
    border: none;
    color: $secondary !important;
    font-weight: bold;
    font-size: 26px;
    padding: 1rem;
  }
}

.form-control {
  border: 1px solid #ced4da;
  color: $secondary !important;
}

.form-control::placeholder {
  color: $gray-700 !important;
}

.form-group label {
  font-weight: 500;
  color: $secondary;
}

tabset ul li .active {
  color: $secondary !important;
  font-weight: 600;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: $primary-hover;
  font-weight: 600;
}

/* Mancano btn link però bisogna vedere come fare siccome vengono utilizzati da molte parti */
button {
  &.btn-link {
    &--reset {
      text-decoration: underline;
      font-weight: 400;
      font-size: 16px;
      color: #79757D;
    }
  }
}

.btn-outline-danger:hover {
  color: #ffffff !important;
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

.badge {
  font-size: 0.8rem !important;

  &.bg {
    &--accepted {
      background-color: #89DAB4;
      color: #12613C;
    }

    &--rejected {
      background-color: #EFB49E;
      color: #902E0A;
    }

    &--reported {
      background-color: #ACC8E5;
      color: #112A46;
    }

    &--to_accept {
      background-color: #FFB52B;
      color: #66491E;
    }

    &--to_sign {
      background-color: #CA94BB;
      color: #560F42;
    }

    &--active {
      background-color: #89DAB4;
      color: #12613C;
    }

    &--inactive {
      background-color: #DAD6D4;
      color: #4D413D;
    }

    &--suspended {
      background-color: #EFB49E;
      color: #902E0A;
    }
  }
}

//stile tabella dinamica
.dynamic-table {
  overflow-x: auto;

  table {
    width: 100% !important;
    // display: block;
    //overflow-x: auto;
  }

  tr.mat-header-row {
    //modifica header della tabella
  }

  tr.mat-row,
  tr.mat-footer-row {
    //modifica  di tutte le righe della tabella
    cursor: pointer;
  }

  tbody tr:nth-child(odd) {
    //modifica righe dispari
    background-color: #f0f7fb;
  }

  tbody tr:nth-child(even) {
    //modifica righe pari
  }

  .mat-paginator {
    //utilizzato per la modifica dello stile del paginatore
  }

  .selected-row-color {
    background-color: #bfe8ff !important;
  }

  .selectable:hover {
    background-color: #bfe8ff !important;
  }

  .mat-radio-label {
    margin: 0;
  }

  .mat-checkbox-layout {
    margin: 0;
  }

  .triangle {
    left: 21px;
  }
}

.mat-filter-list-container {
  .list-element:nth-child(odd) {
    //modifica righe dispari
  }

  .list-element:nth-child(even) {
    background-color: #f0f7fb;
    //modifica righe pari
  }

  .list-element-hoverable:hover {
    background-color: #bfe8ff !important;
    cursor: pointer !important;
  }

  .list-element-selected {
    background-color: #bfe8ff !important;

  }

  .list-container {
    max-height: 200px !important;
  }

  .check-container {
    border-right: 2px solid white;
  }

}

.table-row-icon {
  font-size: 16px;
}

.alert {
  &--info {
    background-color: #c2edfe;
    color: $black;
    border-radius: 1rem;
    border-color: transparent;
  }
}

.alert {
  &--info {
    background-color: #c2edfe;
    color: $black;
    border-radius: 1rem;
    border-color: transparent;
  }
}

.form-control:disabled,
.form-control[readonly] {
  cursor: auto !important;
}

.alert-detail-info {
  background-color: #C2EDFE;
  border-radius: 1rem;
  box-shadow: 0px 2px 10px 0px rgba(35, 119, 212, 0.2);
}

.sidebar-nav .nav-group.show>.nav-group-toggle::after {
  transform: rotate(360deg) !important;
}

.sidebar-nav .nav-group-toggle::after {
  transform: rotate(270deg) !important;
}

app-default-header.header {
  box-shadow: -5px 19px 15px -6px rgba(60, 75, 100, 0.15) !important;
}

.dropdown-item:hover {
  background-color: $primary !important;
  color: white !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

.sidebar-nav .nav-group .nav-group-items .nav-item .nav-link {
  padding: 0.75rem 6rem !important;
}

.sidebar-nav .nav-group .nav-group-items .nav-item .nav-link:hover {
  text-decoration: none !important;
}

a.nav-link.nav-group-toggle {
  text-decoration: none !important;
  font-weight: bolder !important;
}

c-sidebar-nav-link-content {
  font-weight: bolder !important;
}

a.nav-link {
  text-decoration: none !important;
}

.tab-content {
  padding: 1rem !important;
  border-right: 1px solid #eceeef;
  border-left: 1px solid #eceeef;
  border-bottom: 1px solid #eceeef;
}

.form-group {
  margin-bottom: 1rem !important;
}

.sidebar-nav .nav-title {
  margin-top: 0 !important;
  background-color: #ffffff0d !important;
}

.sidebar-nav .nav-group.show+.show {
  margin-top: 0 !important;
}

.header {
  --cui-subheader-min-height: 2rem !important;
  --cui-header-min-height: 3rem !important;
}

.sidebar {
  --cui-sidebar-nav-title-padding-y: 0.56rem !important;
  --cui-sidebar-brand-height: 3rem !important;
  --cui-sidebar-toggler-indicator-height: 3.3rem !important;
}

.footer {
  --cui-footer-min-height: 2rem !important;
}


.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.nav-tabs .nav-link {
  color: #888;
}

.no-focus{
  box-shadow: none!important;
}

.focus-cs{
  border: 1px solid #ced4da;
  color: var(--cui-input-focus-color, #1A1B41);
  background-color: var(--cui-input-focus-bg, white);
  border-color: var(--cui-input-focus-border-color, #91bbea) !important;
  outline: 0;
  box-shadow: none!important;
}

.forgotPW{
  font: 400 16px / 28px Titillium Web;
  color: #545454;
  letter-spacing: 0.0094em;
}

ng-invalid.ng-touched:not(form):not(.no-validate) {
  border: 1px solid #ff7851 !important;
  border-radius: 0.25rem !important;
}

.ng-invalid.ng-touched:not(input):not(.no-validate):not(div) {
  border: none !important;
  border-radius: 0.25rem !important;
}

.mat-error {
color: #ff7851 !important;
}

.err-min-space{
  min-height: 21px !important;
}

.err-form-group-min-space{
  min-height: 87px !important;
  //margin-bottom: 0px !important;
}


.mat-card-header .mat-card-title{
  background-color: transparent !important;
  border: none !important;
  color: #4D7A9E !important;
  font-size: 26px !important;   
}

.mat-card-content .ng-invalid.ng-touched:not(form):not(.no-validate) {
  border: none !important;
  border-radius: 0.25rem !important;
}

.mat-card-content .ng-invalid.ng-touched:not(div):not(.no-validate) {
  border:  1px solid red !important;
  border-radius: 0.25rem !important;
}

.subtitle {
  font-weight: bold;
  font-size: 20px;
  color: #4D7A9E !important;
}
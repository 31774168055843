// Variable override
$background: #a2d2ef;
$gray-100: #f8f9fa !default;
$gray-200: #f7f7f9 !default;
$gray-300: #eceeef !default;
$gray-400: #ced4da !default;
$gray-500: #aaa !default;
$gray-600: #888 !default;
$gray-700: #5a5a5a !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$white: #fff !default;
$black: #12132E !default;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff7851 !default;
$orange: #fd7e14 !default;
$yellow: #ffce67 !default;
$green: #56cc9d !default;
$teal: #20c997 !default;
$cyan: #6cc3d5 !default;

$primary: #2377D4 !default;
$primary-hover: #0072F0 !default;
$primary-active: #004397 !default;
$secondary: #1A1B41 !default;
$secondary-opacity: #070a36 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$dark: $gray-800 !default;
$body-bg: lighten($background, 46%) !default;
$body-color: $secondary !default;
/* $yiq-contrasted-threshold: 250 !default; */
$border-radius: 0.4rem !default;
$border-radius-lg: 0.6rem !default;
$border-radius-sm: 0.3rem !default;
$headings-font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif !default;
$headings-color: $gray-700 !default;
$table-border-color: rgba(0, 0, 0, 0.05) !default;
$table-bordered: 0.6rem !default;
/* $breadcrumb-bg:                     $white !default;
$breadcrumb-divider-color:          $dark !default;
$breadcrumb-active-color:           $breadcrumb-divider-color !default; */
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $info !default;
$navbar-dark-color: rgba($white, 0.6) !default;
$navbar-dark-hover-color: $white !default;
$navbar-light-color: rgba($black, 0.3) !default;
$navbar-light-hover-color: $gray-700 !default;
$navbar-light-active-color: $gray-700 !default;
$navbar-light-disabled-color: rgba($black, 0.1) !default;
/* $link-color:                        darken($primary, 60%) !default;
$link-hover-color:                  darken($link-color, 15%) !default; */
